<template>
  <a-layout>
    <a-header class="header">
      <a-image
        :width="50"
        :src="logoSrc"
        :preview="false"
      />
      <a-space>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleMenuClick">
            <a-menu-item key="1">
              <LockOutlined />
              修改密码
            </a-menu-item>
          </a-menu>
        </template>
          <setting-outlined :style="{ color: '#2e60ff',fontSize:'20px' }"/>
      </a-dropdown>
      <poweroff-outlined :style="{ color: '#2e60ff',fontSize:'20px' }" @click="onExit"/>
      </a-space>
    </a-header>
    <a-layout class="content">
      <a-layout-sider class="sider">
        <a-menu
          v-model:openKeys="openKeys"
          v-model:selectedKeys="selectedKeys"
          :inlineCollapsed="false"
          @select="onItemSelect"
          mode="inline"
          theme="light"
          class="memu"
        >
          <a-sub-menu :key="subItem.moduleId" v-for="subItem in modules">
            <!--<template #icon>-->
            <!--{{subItem.icon}}-->
            <!--</template>-->
            <template #title>{{subItem.title}}</template>
            <a-menu-item :key="item.moduleId" v-for="item in subItem.modules">
              {{item.title}}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-content class="content-right">
        <a-tabs v-model:activeKey="activeKey" hide-add type="editable-card" @edit="onEdit" @change="onChange">
          <a-tab-pane v-for="tab in tabs" :key="tab.key" :tab="tab.title" :closable="tab.closable">
            <!--<TabPage :src="tab.src"></TabPage>-->
            <component :is="tab.content" @open-yj-detail="eventFn" v-if="tab.content=='YjReport'"></component>
            <component :is="tab.content"  :name="tab.name" :user-id="tab.userId" :exam-record-id="tab.examRecordId"  v-else-if="tab.content=='YjReportDetail'"></component>
            <component :is="tab.content" v-else></component>
          </a-tab-pane>
        </a-tabs>
      </a-content>
    </a-layout>
  </a-layout>
  <a-modal v-model:visible="isShowWin" :title="formData.title" :footer="null">
    <a-form
      ref="formRef"
      name="custom-validation"
      :model="formData"
      :rules="rules"
      v-bind="layout"
      @finish="handleFinish"
    >
      <div class="form-item">
        <a-form-item has-feedback label="原密码" name="oldPass">
          <a-input-password v-model:value="formData.oldPass" placeholder=""/>
        </a-form-item>
      </div>
      <div class="form-item">
        <a-form-item has-feedback label="密码" name="pass">
          <a-input-password v-model:value="formData.pass" placeholder=""/>
        </a-form-item>
      </div>
      <div class="form-item">
        <a-form-item has-feedback label="确认密码" name="checkPass">
          <a-input-password v-model:value="formData.checkPass" placeholder=""/>
        </a-form-item>
      </div>
      <div class="form-btns">
        <a-button type="primary" html-type="submit">提交</a-button>
      </div>
    </a-form>
  </a-modal>
  <a-modal
    v-model:visible="isShowExit"
    title="提示"
    ok-text="确认"
    ok-type="primary"
    cancel-text="取消"
    @ok="handleExit"
  >
    是否退出登录
  </a-modal>
</template>
<script>
import {defineComponent, toRefs, ref, reactive, onMounted} from 'vue';
import {PoweroffOutlined, ExclamationCircleOutlined, SettingOutlined, LockOutlined} from '@ant-design/icons-vue';
import Personnel from '@/views/personnel/Personnel.vue';
import Member from '@/views/personnel/Member.vue';
import HealthData from '@/views/health/HealthData.vue';
import EcgData from '@/views/health/EcgData.vue';
import MxjkData from '@/views/health/MxjkData.vue';
import FeedBack from '@/views/info/FeedBack.vue';
import Appeal from '@/views/info/Appeal.vue';
import Cost from '@/views/ecg/Cost.vue';
import Surplus from '@/views/ecg/Surplus.vue';
import Bill from '@/views/ecg/Bill.vue';
import Order from '@/views/ecg/Order.vue';
import User from '@/views/system/User.vue';
import Device from '@/views/system/Device.vue';
import ChartIndex from '@/views/chart/Index.vue';
import Unit from '@/views/chartData/Unit.vue';
import Activity from '@/views/chartData/Activity.vue';
import YjReport from '@/views/health/YjReport.vue';
import YjReportDetail from '@/views/common/YjReportDetail.vue';
import ChartOther from '@/views/chartData/Other.vue';
import DataUpload from '@/views/his/DataUpload.vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {message} from 'ant-design-vue';

export default defineComponent({
  components: {
    PoweroffOutlined,
    ExclamationCircleOutlined,
    SettingOutlined,
    LockOutlined,
    Personnel,
    Member,
    HealthData,
    MxjkData,
    EcgData,
    FeedBack,
    Appeal,
    Cost,
    Surplus,
    Bill,
    Order,
    User,
    Device,
    ChartIndex,
    Unit,
    Activity,
    YjReport,
    YjReportDetail,
    ChartOther,
    DataUpload
  },
  setup() {
    /*
     * const activeKey = ref('Personnel');
     * const newTabIndex = ref(0);
     */
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      collapsed: false,
      logoSrc: require('../../public/img/logo.png'),
      modules: [],
      selectedKeys: [],
      openKeys: [],
      preOpenKeys: [],
      activeKey: '1-1',
      tabs: [
        /*
         * {
         *   key: '1-1',
         *   title: '人员管理',
         *   content: 'Personnel',
         *   closable: true
         * }
         */
      ],
      isShowWin: false,
      isShowExit: false
    });
    onMounted(() => {
      store.dispatch('user/queryModule').then(res => {
        const modules = res.data;
        data.modules.push(...modules);
        const parnetModule = modules[0];
        const module = parnetModule.modules[0];
        data.selectedKeys.push(module.moduleId);
        data.openKeys.push(parnetModule.moduleId);
        data.preOpenKeys.push(parnetModule.moduleId);
        data.activeKey = module.moduleId;
        data.tabs.push({
          key: module.moduleId,
          title: module.title,
          content: module.content,
          closable: true
        });
        // debugger;
      });
    });
    /*
     * watch(() => data.openKeys, (_val, oldVal) => {
     *   data.preOpenKeys = oldVal;
     * });
     * const toggleCollapsed = () => {
     *   data.collapsed = !data.collapsed;
     *   data.openKeys = data.collapsed ? [] : data.preOpenKeys;
     * };
     */
    const onItemSelect = menuInfo => {
      const tab = data.tabs.find(item => item.key === menuInfo.key);
      if (tab) {
        data.activeKey = menuInfo.key;
      } else {
        const keyPath = menuInfo.keyPath;
        const subMenus = data.modules.find(item => item.moduleId === keyPath[0]);
        if (subMenus) {
          const item = subMenus.modules.find(item => item.moduleId === keyPath[1]);
          if (item) {
            data.tabs.push({
              key: item.moduleId,
              title: item.title,
              content: item.content,
              closable: true
            });
            data.activeKey = item.moduleId;
          }
        }
      }
    };
    const remove = (targetKey) => {
      let lastIndex = 0;
      data.tabs.forEach((tab, i) => {
        if (tab.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      data.tabs = data.tabs.filter(tab => tab.key !== targetKey);

      if (data.tabs.length && data.activeKey === targetKey) {
        if (lastIndex >= 0) {
          data.activeKey = data.tabs[lastIndex].key;
        } else {
          data.activeKey = data.tabs[0].key;
        }
        data.selectedKeys = [];
        data.selectedKeys.push(data.activeKey);
      } else {
        if (!data.tabs.length) {
          data.selectedKeys = [];
        }
      }
    };

    const onEdit = (targetKey) => {
      remove(targetKey);
    };
    const onChange = (activeKey) => {
      data.selectedKeys = [];
      data.selectedKeys.push(activeKey);
      const key = activeKey.split('-')[0];
      data.openKeys.push(key);
      data.openKeys = [...new Set(data.openKeys)];
    };
    const onExit = () => {
      data.isShowExit = true;
      /*
       * Modal.confirm({
       *   title: '是否退出登录',
       *   icon: createVNode(ExclamationCircleOutlined),
       *   content: '',
       *   okText: '确认',
       *   okType: 'primary',
       *   cancelText: '取消',
       *   onOk() {
       *     return new Promise((resolve, reject) => {
       *       store.dispatch('user/exit').then(res => {
       *         resolve();
       *         router.push('/login');
       *       });
       *     }).catch(() => console.log('Oops errors!'));
       *     return
       *   },
       *   onCancel() {
       *     console.log('Cancel');
       *   }
       * });
       */
    };
    const handleExit = () => {
      store.dispatch('user/exit').then(res => {
        data.isShowExit = false;
        router.push('/login');
      });
    };
    const handleMenuClick = e => {
      switch (e) {
        // 修改密码
        case '1':
        default:
          data.isShowWin = true;
          break;
      }
    };
    const formRef = ref();
    const formData = reactive({
      title: '修改密码',
      oldPass: '',
      pass: '',
      checkPass: ''
    });
    const validatePass = async(rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('请输入密码'));
      } else {
        if (formData.checkPass !== '') {
          formRef.value.validateFields('checkPass');
        }
        return Promise.resolve();
      }
    };
    const validatePass2 = async(rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('确认输入密码'));
      } else if (value !== formData.pass) {
        return Promise.reject(new Error('两次输入的密码不一致!'));
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      pass: [
        {
          required: true,
          validator: validatePass,
          trigger: 'change'
        }
      ],
      checkPass: [
        {
          validator: validatePass2,
          trigger: 'change'
        }
      ],
      oldPass: [
        {
          required: true,
          message: '原密码不能为空'
        }
      ]
    };
    const layout = {
      labelCol: {
        style: {
          width: '100px'
        }
      },
      wrapperCol: {
        span: 14
      }
    };
    const handleFinish = values => {
      // submitData(formState);
      store.dispatch('user/updateUserPass', formData).then(res => {
        if (res && res.data && res.data.success) {
          data.isShowWin = false;
          const msg = '修改成功';
          message.success(msg);
        } else {
          let msg = '修改失败,请重试';
          if (res.data.status === 1002) {
            msg = '原密码错误！';
          }
          message.error(msg);
        }
      });
    };
    const eventFn = (val) => {
      data.tabs.push({
        key: val.reportId,
        title: val.name + '的报告',
        content: 'YjReportDetail',
        closable: true,
        userId: val.userId,
        examRecordId: val.examRecordId,
        name: val.name
      });
      data.activeKey = val.reportId;
    };
    return {
      ...toRefs(data),
      onEdit,
      onChange,
      onItemSelect,
      onExit,
      handleExit,
      handleMenuClick,
      formData,
      formRef,
      rules,
      layout,
      handleFinish,
      eventFn
    };
  }
});
</script>
<style lang="scss" scoped>
  @import '@/style/index.scss';

  #components-layout-demo-fixed-sider .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }

  [data-theme='dark'] .site-layout .site-layout-background {
    background: #141414;
  }
</style>
